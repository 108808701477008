<template>
  <div class="page">
    <span style="position: relative;">

      <el-input placeholder="请输入搜索关键字" v-model="search" prefix-icon="el-icon-search" clearable class="search"
        @input="inputSearch" @blur="keyupSearch($event)" @keyup.enter.native="$event.target.blur()">
      </el-input>
      <ul class="search_ul" v-if="inputData.length > 0">
        <li v-for="(item, index) in inputData" :key="index" @click="handleDetail(item)">
          {{ item.value }}
        </li>
      </ul>
    </span>
    <div ref="middle">
      <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick" class="tabs">
        <el-tab-pane label="全部院校" name="first">
          <!-- <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" />
          <van-dropdown-item v-model="value2" :options="option2" />
          <van-dropdown-item v-model="value3" :options="option3" />
        </van-dropdown-menu> -->
          <ul class="list" v-if="listData.length > 0">
            <li v-for="(item, index) in listData" :key="index" class="item">
              <img :src="item.logo" class="logoUrl" />
              <div class="cont">
                <div class="cont_box">
                  <div class="cnName" @click="handleDetail(item)">
                    {{ item.name }}
                  </div>
                  <div class="mt3">
                    <span class="feature" v-for="(feat, idx) in item.features" :key="idx">
                      {{ feat }}
                    </span>
                  </div>
                </div>
                <div class="collegeDesc">
                  {{ item.cityName }} /
                  <span v-for="(cate, idx1) in item.categories" :key="idx1">
                    {{ cate }}
                  </span>
                  / {{ item.natureType }} / 排名:{{ item.rank }}
                </div>
              </div>
              <div class="right" @click="handleCompare(item)">
                <i class="el-icon-circle-plus-outline icons"></i>
                对比
              </div>
            </li>
          </ul>
          <div v-else class="no_data">
            <img :src="noData" />
            <span class="text">暂无数据</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="大学排名" name="second">
          <van-dropdown-menu class="rank2">
            <van-dropdown-item :title="rankVal.data && rankVal.data.year" ref="rank_21">
              <div class="rank-li" v-for="(item, i) in collegeRankConfig" :key="i" @click="clickRankConfig1(item, 21)"
                :class="item.year == rankVal.data.year ? 'rank-li-active' : ''">
                {{ item.year }}
              </div>
            </van-dropdown-item>
            <van-dropdown-item :title="rankVal.name" ref="rank_22">
              <div class="rank-li" v-for="(item, i) in rankVal.data && rankVal.data.rankTypes" :key="i"
                @click="clickRankConfig2(item, 22)" :class="item == rankVal.name ? 'rank-li-active' : ''">
                {{ item }}
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
          <ul class="list listPad" v-if="rankData.length > 0">
            <li v-for="(item, index) in rankData" :key="index" class="item">
              <img :src="item.logo" class="logoUrl" />
              <div class="cont">
                <div class="cont_box">
                  <div class="cnName" @click="handleDetail(item)">
                    {{ item.name }}
                  </div>
                  <div class="mt3">
                    <span class="feature" v-for="(feat, idx) in item.features" :key="idx">
                      {{ feat }}
                    </span>
                  </div>
                </div>
                <div class="collegeDesc">
                  {{ item.cityName }} /
                  <span v-for="(cate, idx1) in item.categories" :key="idx1">
                    {{ cate }}
                  </span>
                  / {{ item.natureType }} / 排名:{{ item.rank }}
                </div>
              </div>
              <div class="right" @click="handleCompare(item)">
                <i class="el-icon-circle-plus-outline icons"></i>
                对比
              </div>
            </li>
          </ul>
          <div v-else class="no_data">
            <img :src="noData" />
            <span class="text">暂无数据</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="院校对比" name="third">
          <div class="comp_box" v-if="compareData.length > 0">
            <div class="del" @click="handleDelShow">
              <template v-if="isDel">
                <i class="el-icon-delete icons"></i>
                删除
              </template>
              <template v-else> 取消删除 </template>
            </div>
            <ul class="list">
              <el-checkbox-group v-model="checkList" @change="handleChecked" :max="4">
                <li v-for="(item, index) in compareData" :key="index" class="item">
                  <el-checkbox :label="item.id">
                    <img :src="item.logo" class="logoUrl" />
                    <div class="cont">
                      <div class="cont_box">
                        <div class="cnName">{{ item.name }}</div>
                        <div class="mt3">
                          <span class="feature" v-for="(feat, idx) in item.features" :key="idx">
                            {{ feat }}
                          </span>
                        </div>
                      </div>
                      <div class="collegeDesc">
                        {{ item.cityName }} /
                        <span v-for="(cate, idx1) in item.categories" :key="idx1">
                          {{ cate }}
                        </span>
                        / {{ item.natureType }} / 排名:{{ item.rank }}
                      </div>
                    </div>
                    <div class="right" v-if="!isDel" @click="handleDel(item)">
                      <i class="el-icon-delete icons"></i>
                      删除
                    </div>
                    <div class="right" v-else>
                      <i class="el-icon-check icons icons_border"></i>
                    </div>
                  </el-checkbox>
                </li>
              </el-checkbox-group>
            </ul>
            <div class="comp_btn">
              <div class="text">已选 {{ checkList.length }}/4</div>
              <el-button type="primary" :style="checkList.length < 2 ? 'opacity: 0.5;' : 'opacity: 1;'"
                @click="clickCompare">开始对比</el-button>
            </div>
          </div>
          <div v-else class="no_data">
            <img :src="noData" />
            <span class="text">暂无数据</span>
          </div>
        </el-tab-pane>
      </el-tabs>
      <p slot="append" class="center p-30" v-if="noMore">
        以上为全部
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      search: '',
      activeName: 'first',
      listData: [], //全部院校
      noData: noData,
      rankData: [], //排名
      hot: {
        year: '',
        type: '',
        page: '1',
      },
      compareData: [], //对比
      checkList: [],
      isDel: true,
      search1: {
        keyword: '',
        provinces: [],
        features: [],
        categories: [],
        sort: [],
      },
      search2: {
        year: '',
        type: '',
        page: 1,
      },
      checkedData: [],
      inputData: [],
      noMore: false,
      userInfoData: null,
      collegeRankConfig: [],
      rankVal: {
        data: null,
        name: '',
      },
    };
  },
  mounted() {
    console.log('wzr3')
    this.getCollegeList();
    window.addEventListener('scroll', this.windowScroll);
    let local = JSON.parse(localStorage.getItem('localProvinceInfo')) || [];
    console.log('local', local);
    // this.search1.provinces = local.id;

    this.userInfoData = JSON.parse(localStorage.getItem('userInfo')) || [];
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll); //销毁滚动事件
  },
  methods: {
    //获取当前可视范围的高度
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      } else {
        clientHeight = Math.max(
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
      }
      return clientHeight;
    },

    //获取文档完整的高度
    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
    },
    // 防抖函数
    debounce(fn, delay) {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          console.log('防抖');
          fn.apply(context, args);
        }, delay);
      };
    },
    //获取当前滚动条的位置
    getScrollTop() {
      var scrollTop = 0;
      //window.pageYOffset = document.documentElement.scrollTop
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    //回调函数
    async windowScroll() {
      //获取三个值
      var scrollTop = this.getScrollTop();
      var clientHeight = this.getClientHeight();
      var scrollHeight = this.getScrollHeight();
      let top1 = this.$refs.middle.offsetTop;
      scrollTop > top1 + 133 ? (this.isFixed = true) : (this.isFixed = false);
      //如果满足公式则，确实到底了
      // console.log(scrollTop + clientHeight, scrollHeight)
      // 绝对值函数
      console.log(parseInt(String(scrollTop + clientHeight)) - scrollHeight);
      //20230922修改加载方式
      // if (
      //   parseInt(String(scrollTop + clientHeight)) - scrollHeight === -1 ||
      //   parseInt(String(scrollTop + clientHeight)) - scrollHeight === 0
      // ) {
      //   //发送异步请求请求数据，同时携带offset并自增offset
      //   //noMore是自定义变量，如果是最后一批数据则以后都不加载
      //   console.log('滚动到底部');
      //   if (!this.noMore) {
      //     if (this.activeName == 'first') {
      //       this.search1.sort = this.listData[this.listData.length - 1].sort;
      //       console.log();
      //       this.debounce(this.getCollegeList(), 3000);
      //     }
      //     if (this.activeName == 'second') {
      //       this.search2.page = this.search2.page + 1;
      //       this.debounce(this.getCollegeListHot(), 3000);
      //     }
      //   }
      // }
      if (scrollTop + clientHeight >= scrollHeight - 8) {
        //发送异步请求请求数据，同时携带offset并自增offset
        //noMore是自定义变量，如果是最后一批数据则以后都不加载
        console.log("滚动到底部");
        if (!this.noMore) {
          if (this.activeName == 'first') {
            this.search1.sort = this.listData[this.listData.length - 1].sort;
            this.debounce(this.getCollegeList(), 3000);
          }
          if (this.activeName == 'second') {
            this.search2.page = this.search2.page + 1;
            this.debounce(this.getCollegeListHot(), 3000);
          }
        }
      }
    },
    //search
    async inputSearch() {
      if (this.search.length < 2) {
        return true;
      }
      if (!(this.userInfoData && this.userInfoData.province)) {
        return true;
      }
      let apiUrl = `/php/plan.keywords.get?keywords=${this.search}&pcId=10&type=college`;
      const data = {
        keyword: this.search,
        pcId: 10,
        type: 'college',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);

        if (res.data.ret == 0) {
          this.inputData = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    async keyupSearch(event) {
      event.target.blur();
      this.listData = [];
      this.inputData = [];
      this.search1.keyword = this.search;
      this.search1.provinces = [];
      this.search1.features = [];
      this.search1.categories = [];
      this.search1.sort = [];
      console.log('wzr2')
      await this.getCollegeList();
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName == 'first') {
        this.search1.sort = [];
        console.log('wzr1')
        this.getCollegeList();
      }
      if (this.activeName == 'second') {
        this.search2.page = 1;
        this.getCollegeRankConfig();
        this.getCollegeListHot();
      }
      if (this.activeName == 'third') {
      }
    },
    //大学排名-筛选
    async getCollegeRankConfig() {
      let apiUrl = '/php/college.rank.config';
      const res = await this.$axios.get(apiUrl);
      console.log('==res', res);
      let list = [];
      if (res.data.ret == 0) {
        list = res.data.data;
      }
      this.collegeRankConfig = list;
      this.rankVal.data = this.collegeRankConfig[0];
      this.rankVal.name = this.collegeRankConfig[0].rankTypes[0];
      this.search2.year = this.rankVal.data.year;
      this.search2.type = this.rankVal.name;
      this.search2.page = 1;
      this.getCollegeListHot()
    },
    //点击年份
    clickRankConfig1(item, i) {
      this.rankData = [];
      this.rankVal.data = item;
      this.rankVal.name = item.rankTypes[0];
      this.$refs[`rank_${i}`].toggle();
      this.search2.year = item.year;
      this.search2.type = this.rankVal.name;
      this.search2.page = 1;
      this.getCollegeListHot()
    },
    //难以排名
    clickRankConfig2(item, i) {
      this.rankData = [];
      this.rankVal.name = item;
      this.$refs[`rank_${i}`].toggle();
      this.search2.type = this.rankVal.name;
      this.search2.page = 1;
      this.getCollegeListHot()
    },
    clickRankBtn(i) {
      this.$refs[`rank_${i}`][0].toggle();
    },
    //获取大学列表
    async getCollegeList() {
      let apiUrl = '/php/college.list';
      const res = await this.$axios.post(apiUrl, this.search1);
      console.log('==res', res);
      let list = [];
      if (res.data.ret == 0) {
        list = res.data.data.list;
      }
      console.log('wzr=====1:', this.listData.length)
      this.listData = [...this.listData, ...list];
    },
    objFun(item) {
      let obj = JSON.parse(JSON.stringify(item));
      let arr = [];
      for (let key in obj) {
        arr.push(key + '=' + obj[key]);
      }
      let str = arr.join('&');
      return str;
    },
    //大学排名
    async getCollegeListHot() {
      let str = this.objFun(this.search2);
      let apiUrl = `/php/college.rank.list?${str}`;
      const data = {
        year: '',
        type: '',
        page: '1',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        let list = [];
        if (res.data.ret == 0) {
          list = res.data.data.list;
        }
        this.rankData = [...this.rankData, ...list];
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //院校对比
    handleCompare(item) {
      if (this.compareData.length > 9) {
        this.$message({
          message: '院校对比最多添加10条',
          type: 'warning',
          // duration:0
        });
        return false;
      }
      const ret = this.compareData.find((ele) => {
        return ele.id === item.id;
      });
      console.log('院校对比', ret);
      if (!ret) {
        this.$message({
          message: '添加成功',
          type: 'success',
          // duration:0
        });
        // this.$toast( '添加成功');
        this.compareData.push(item);
      } else {
        this.$message({
          message: '改院校已加入对比！',
          type: 'warning',
          // duration:0
        });
        // this.$toast( '改院校已加入对比！');
      }
    },
    handleChecked(e) {
      console.log('====handleChecked', e);
      this.checkedData = e;
    },
    //删除
    handleDelShow() {
      this.isDel = !this.isDel;
    },
    handleDel(item) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        center: true,
      })
        .then(() => {
          let list = [];
          this.compareData.forEach((ele) => {
            if (ele.id != item.id) {
              list.push(ele);
            }
          });
          this.compareData = list;
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          }, 300);
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除',
          });
        });
    },
    //
    clickCompare() {
      console.log('===ww', this.checkList);
      if (this.checkList.length > 1) {
        // let list = this.checkList.map((ele) => {
        //   return ele.id;
        // });
        this.$router.push({
          path: '/colleges/compare',
          query: { id: this.checkedData.toString() },
        });
      }
    },

    //院校详情
    handleDetail(item) {
      this.$router.push({ path: '/colleges/detail', query: { id: item.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 50px;
}

.search {
  padding: 10px 12px;
  display: flex;
  align-items: center;

  /deep/ .el-input__inner {
    border: none !important;
    height: 40px !important;
    background-color: #f7f8fa;
    border-radius: 2px;
    color: #323233;
    font-size: 14px;
  }

  /deep/ .el-input__prefix {
    left: 15px;
    color: #323233;
  }

  /deep/ .el-input__suffix {
    right: 15px;
  }
}

.search_ul {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: calc(100vw - 28px);
  left: 15px;
  border: 1px solid #ddd;
  border-top: none;
  /* top: 0; */
  margin-top: -10px;
  border-radius: 5px;
  padding: 10px 0;

  li {
    font-size: 14px;
    padding: 6px 20px;
  }
}

.tabs {
  .el-tabs__header {
    border-bottom: 1px solid #f2f2f2;
    height: 44px;
    margin: 0;
    line-height: 44px;
  }

  .el-tabs__nav {
    // display: inline-flex;
    height: 44px;
    // justify-content: space-evenly;
    width: 100%;
  }

  .el-tabs__item {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    line-height: 42px;
    padding: 0 15px !important;
  }

  .el-tabs__item.is-active {
    color: #910000 !important;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #910000;
    border-radius: 5px;
    width: 55px !important;
    margin-left: 22px;
  }
}

.list {
  padding: 10px 15px;

  li.item {
    border-bottom: 1px solid #f2f2f2;
    min-height: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  li.item,
  /deep/ .el-checkbox,
  /deep/ .el-checkbox__label {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    justify-content: space-between;
    font-size: 14px;

    .logoUrl {
      width: 50px;
    }

    .cont {
      width: calc(100% - 140px);
      color: #666;

      .cont_box {}

      .cnName {
        font-size: 16px;
        color: #212121;
        margin-right: 10px;
        font-weight: 500;
      }

      .mt3 {
        text-align: left;
        margin: 5px 0;
      }

      .feature {
        height: 14px;
        line-height: 1.2;
        padding: 1px 3px;
        background: #fff;
        color: #666;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 4px 4px 4px 4px;
        margin-right: 5px;
      }

      .collegeDesc {
        font-size: 12px;
        font-weight: 400;
        color: #9b9b9b;
      }
    }

    .right {
      width: 50px;
      text-align: right;
      font-size: 12px;
      font-weight: 400;
      color: #9b9b9b;
      display: inline-flex;
      align-items: center;
      justify-content: right;

      .icons {
        font-size: 18px;
        margin-right: 8px;
      }
    }

    .el-checkbox__input {
      position: absolute;
      right: 0;
      z-index: -99;
    }

    .icons_border {
      border: 1px solid #666;
      border-radius: 5px;
      background: #fff;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
      color: #fff;
    }
  }

  .el-checkbox__label {
    padding-left: 0;
  }

  .el-checkbox.is-checked {
    .icons_border {
      border: 1px solid #910000;
      border-radius: 5px;
      background: #910000;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }

  .el-checkbox.is-disabled {
    .icons_border {
      background-color: #edf2fc;
      border-color: #dcdfe6;
      color: #edf2fc;
      cursor: not-allowed;
    }
  }
}

.listPad {
  padding-top: 5px;

  li.item:nth-child(1) {
    padding-top: 0;
  }
}

.del {
  padding: 10px 15px 0;
  text-align: right;
  font-size: 14px;

  .icons {
    font-size: 18px;
    margin-right: 1px;
    vertical-align: text-top;
  }
}

.comp_box {
  padding-bottom: 80px;

  .comp_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    background: #fff;

    /deep/ .el-button--primary {
      background: #910000;
      border-color: #910000;
      // opacity: 0.5;
      padding: 10px 30px;
    }
  }
}

.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;

  img {
    width: 180px;
  }

  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}

.el-message-box {
  width: 90%;

  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }

  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}

.van-dropdown-menu {
  font-size: 12px;
  font-weight: 800;
}

.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-bottom: none;
}

.rank-li {
  font-size: 14px;
  margin: 0 20px;
  line-height: 40px;
  border-bottom: 1px solid #f2f2f2;
  font-weight: normal;
  padding: 0 8px;
}

.rank2 {
  width: fit-content;
  padding: 0 24px;

  /deep/ .van-dropdown-menu__item:nth-child(2) {
    min-width: 32vw;
  }

  /deep/ .van-dropdown-menu__title--active {
    color: #910000;
  }
}

.rank-li-active {
  color: #910000;
}
</style>
